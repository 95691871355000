<template>
  <div class="container">
    <h4 class="text-center my-4">Список студентов</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">


      <div v-if="decryptCheckRole('deputyDean') || decryptCheckRole('admin')">
        <div class="mb-2">
          <Button label="Список победителей" icon="pi pi-user" class="p-button-primary mt-2"
                  @click="openWinnersDialog()"/>
        </div>

        <span class="protocols" v-for="(step, stepIndex) in commissionResearchCup_form.steps" :key="stepIndex">
          <Button :label="'Протокол'+ (stepIndex+1)" icon="pi pi-file-pdf" class="p-button-primary mt-2 me-2"
                @click="openProtocol(step.id)" :disabled="commissionResearchCup_form.stepsProtocolCondition.find(i=>i.step_id == step.id).can_create == 0"/>
        </span>
      </div>

      <div class="mt-2 mb-2">


      </div>


      <DataTable class="mt-2" :value="deputyDeanResearchCup_form.students" showGridlines stripedRows
                 :globalFilterFields="['barcode', 'last_name','first_name']"
                 v-model:filters="filters"
                 :paginator="true"
                 :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 responsiveLayout="scroll"
                 currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}">
        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search"/>
              <InputText v-model="filters['global'].value" placeholder="Поиск студента"/>
            </span>
          </div>
        </template>

        <Column header="№" :style="{width: '60px'}">
          <template #body="{index}">
            {{index+1}}
          </template>
        </Column>
        <Column field="barcode" header="Баркод"></Column>

        <Column header="ФИО">
          <template #body="{data}">
            {{data.last_name}} {{data.first_name}}
          </template>
        </Column>

<!--        <Column field="project_theme_direction_name" header="Направление темы проекта"></Column>-->
        <Column field="theme" header="Тема проекта"></Column>
        <Column header="Дата регистрации">
          <template #body="{data}">
            {{ convertGraduateWorkTimestampToDate(data.created_at) }}
          </template>
        </Column>

        <template v-for="(step, stepIndex) in commissionResearchCup_form.steps" :key="stepIndex">
          <Column :header="'Этап ' + (stepIndex + 1)">
            <template #body="{data}">
              <strong>{{step.name}}</strong>

              <div v-if="data.files?.find(i=>i.step_id == step.id)?.file != null">
                <a :href="data.files?.find(i=>i.step_id == step.id)?.file" target="_blank">Файл</a>
                <br>
                <div v-if="step.start_date < getTimestampInSeconds() && getTimestampInSeconds() < step.end_date">
                  <Button label="Оценка" class="p-button-primary mt-2"
                          @click="openStepScoreDialog(data.student_id, step.id, data.files.find(i=>i.step_id == step.id)?.score)"/>
                </div>
              </div>
              <div v-else>
                <p>Нет файла</p>
              </div>
            </template>
          </Column>
        </template>

<!--        <Column header="Текущий этап">-->
<!--          <template #body="{data}">-->
<!--            <div v-for="(step, stepIndex) in researchCupSteps.filter(i=>i.start_date < getTimestampInSeconds() && i.end_date > getTimestampInSeconds())"-->
<!--                 :key="stepIndex">-->
<!--              <strong>Этап {{stepIndex + 1}}: {{step.name}}</strong>-->


<!--              <div v-if="data.video_file != null">-->
<!--                <a :href="data.video_file" target="_blank">Видео</a>-->
<!--                <br>-->
<!--                <Button label="Оценка" class="p-button-primary mt-2"-->
<!--                        @click="openStepScoreDialog(data.student_id, step.id)"/>-->
<!--              </div>-->
<!--              <div v-else>-->
<!--                <p>Нет файла</p>-->
<!--              </div>-->

<!--            </div>-->

<!--            <div v-if="!researchCupSteps.filter(i=>i.start_date < getTimestampInSeconds() && i.end_date > getTimestampInSeconds())">-->
<!--              <p>Этап еще не начался</p>-->
<!--            </div>-->

<!--          </template>-->
<!--        </Column>-->

<!--        <Column header="Этап II">-->
<!--          <template #body="{data}">-->
<!--            <div v-if="data.article_file != null">-->
<!--              <a :href="data.article_file" target="_blank">Статья</a>-->
<!--            </div>-->
<!--            <div v-else>-->
<!--              <p>Нет файла</p>-->
<!--            </div>-->
<!--          </template>-->
<!--        </Column>-->
<!--        <Column header="Этап III">-->
<!--          <template #body="{data}">-->
<!--            <div v-if="data.presentation_file != null">-->
<!--              <a :href="data.presentation_file" target="_blank">Презентация</a>-->
<!--            </div>-->
<!--            <div v-else>-->
<!--              <p>Нет файла</p>-->
<!--            </div>-->
<!--          </template>-->
<!--        </Column>-->
        <Column field="comment" header="Комментарий"></Column>


      </DataTable>



      <Dialog header="Оценка" v-model:visible="stepScoreDisplay" :breakpoints="{'960px': '75vw'}"
              :style="{width: '50vw'}" :closable="false">

        <div class="col-md-12">
          <div>
            <div class="row mt-4" v-for="(item, index) in commissionResearchCup_form.stepsCriterions.filter(i=>i.research_cup_steps_id == selected_step)"
                 :key="index">
              <label :for="(index+1)" class="col-md-6 col-form-label">
                {{ item.name }} ({{ item.description }}) - <strong>{{item.percent}}%</strong></label>
              <div class="col-md-6">
                <input :id="(index+1)" type="number" class="form-control" :value="selected_score?.find(i=>i.research_cup_steps_criterions_id == item.id)?.score"
                       :disabled="selected_score?.find(i=>i.research_cup_steps_criterions_id == item.id)?.score"
                       placeholder="От 0 до 100" min="0" max="100"
                       @change="changeStepScore(item.id, $event)">

<!--                <InputNumber @change="changeStepScore(item.id, $event)" mode="decimal" :min="0" :max="100"/>-->
              </div>
            </div>
          </div>
        </div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" @click="closeStepScoreDialog" class="p-button-text"/>
          <Button label="Сохранить" icon="pi pi-check" @click="submitStepScore" :disabled="selected_score?.length > 0"/>
        </template>
      </Dialog>


      <Dialog header="Победители" v-model:visible="winnersDisplay" :breakpoints="{'960px': '75vw'}"
              :style="{width: '50vw'}" :closable="false">

        <div class="col-md-12">
          <div v-if="commissionResearchCup_form.winners.length > 0">
            <ul>
              <li v-for="(item, index) in commissionResearchCup_form.winners"
                  :key="index">
                {{item.barcode}} - Курс: {{item.study_course}} - Балл: {{item.score}}
              </li>
            </ul>
          </div>

          <div v-else>
            <p>Список пуст</p>
          </div>

        </div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" @click="closeWinnersDialog" class="p-button-text"/>
        </template>
      </Dialog>

      <ConfirmDialog></ConfirmDialog>




    </div>





  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import {FilterMatchMode, FilterOperator} from "primevue/api";
import {convertGraduateWorkTimestampToDate} from "@/utils/helpers/convertGraduateWorkTimestampToDate";
import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";
import {getCookie} from "@/utils/helpers/cookies.helpers";

export default {
  name: 'StepsScore',
  data() {
    return {
      loading: true,

      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'barcode': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'last_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'first_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
      },
      // steps: [
      //   {
      //     id: 1,
      //     name: 'Видео-визитка',
      //     //start_date: 1673805600,
      //     start_date: 1666926478,
      //     end_date: 1675015200,
      //     academic_year_id: 18
      //   },
      //   {
      //     id: 2,
      //     name: 'Статья',
      //     start_date: 1678384800,
      //     end_date: 1681840800,
      //     academic_year_id: 18
      //   },
      //   {
      //     id: 3,
      //     name: 'Презентация',
      //     start_date: 1682445600,
      //     end_date: 1682532000,
      //     academic_year_id: 18
      //   },
      // ],
      // stepsCriterions: [
      //   {
      //     id: 1,
      //     name: '1',
      //     description: 'qwerty',
      //     percent: '45',
      //     research_cup_steps_id: 1,
      //   },
      //   {
      //     id: 2,
      //     name: '2',
      //     description: 'qwerty',
      //     percent: '35',
      //     research_cup_steps_id: 1,
      //   },
      //   {
      //     id: 3,
      //     name: '3',
      //     description: 'qwerty',
      //     percent: '20',
      //     research_cup_steps_id: 1,
      //   },
      // ],

      // students: [
      //   {
      //     id: 1,
      //     student_id: 2157,
      //     barcode: 2112021,
      //     lastname: 'Иванов',
      //     firstname: 'Иван',
      //     project_theme: 'вапвапвап',
      //     project_theme_direction: 'физика',
      //     video_file: 'https://www.office.com/',
      //     status: 0
      //   }
      // ],
      stepScoreDisplay: false,
      winnersDisplay: false,
      selected_step: null,
      selected_student_id: null,
      selected_score: [],
      stepScore: []


    }
  },
  computed: {
    ...mapState('deputyDeanResearchCup', ['deputyDeanResearchCup_form']),
    ...mapState('commissionResearchCup', ['commissionResearchCup_form'])
  },

  methods: {
    decryptCheckRole,

    ...mapActions('deputyDeanResearchCup', ['GET_REGISTERED_STUDENTS','']),
    ...mapActions('commissionResearchCup', ['POST_RESEARCH_CUP_STUDENT_SCORE','GET_RESEARCH_CUP_STEPS', 'GET_RESEARCH_CUP_STEPS_CRITERIONS', 'GET_RESEARCH_CUP_STEPS_PROTOCOL_CONDITION', 'GET_RESEARCH_CUP_WINNERS']),
    convertGraduateWorkTimestampToDate,

    openWinnersDialog() {
      this.winnersDisplay = true
    },
    closeWinnersDialog() {
      this.winnersDisplay = false
    },
    openProtocol(step_id) {
      window.open("https://back.uib.kz/research_cup/research-cup-score-print/first-protocol?step_id=" + step_id + "&access-token=" + getCookie('ACCESS_TOKEN'));
    },
    getTimestampInSeconds() {
      return Math.floor(Date.now() / 1000)
    },

    openStepScoreDialog(student_id, step, score) {
      this.stepScoreDisplay = true
      this.selected_step = step
      this.selected_score = score
      this.selected_student_id = student_id
      console.log(this.selected_step, 'selected_step')
      console.log(this.selected_score, 'selected_score')
      console.log(this.selected_student_id, 'selected_student_id')
    },
    closeStepScoreDialog() {
      this.stepScoreDisplay = false
      this.selected_step = null
    },

    changeStepScore(research_cup_steps_criterions_id , e, val = 'value') {
      let value = e.target[val]
      if (!this.stepScore.find(i=>i.research_cup_steps_criterions_id == research_cup_steps_criterions_id)) {
        this.stepScore.push({
          score: value,
          research_cup_steps_criterions_id: research_cup_steps_criterions_id,
          student_id: this.selected_student_id,
        })
      }
      else {
        this.stepScore.find(i=>i.research_cup_steps_criterions_id == research_cup_steps_criterions_id).score = value
      }
      console.log(this.stepScore, 'stepScore')
    },

    async submitStepScore() {
      this.stepScoreDisplay = false


      let postData = await this.POST_RESEARCH_CUP_STUDENT_SCORE(this.stepScore)
      if (postData) {
        await this.GET_REGISTERED_STUDENTS()
        this.$message({text: 'Оценки поставлены'})
      }
      this.stepScore = []
      this.selected_step = null
      this.selected_student_id = null
    },


  },
  async mounted() {
    await this.GET_REGISTERED_STUDENTS()
    await this.GET_RESEARCH_CUP_STEPS()
    await this.GET_RESEARCH_CUP_STEPS_CRITERIONS()
    await this.GET_RESEARCH_CUP_STEPS_PROTOCOL_CONDITION()
    await this.GET_RESEARCH_CUP_WINNERS()

    this.loading = false

    //this.commissionResearchCup_form.steps[0].start_date = 1661990400
  },
}

</script>